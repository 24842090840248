import {
    ErrorHandler, Inject, Injectable
} from "@angular/core";
import {
    AbstractRouterService, CommonErrorHandler, InvalidParametersException
} from "@shopliftr/common-ng";
import { DemoEntryRoutingId } from "./enums/demo-routing-id";
import { Router } from "@angular/router";
import { StringUtil } from "@shopliftr/common-js/data";

/**
 * The RouterService for the DemoModule
 *
 * It is the way that Components, Directives and Services will interact with the routes for the DemoModule
 *
 * @see AbstractRouterService
 * @see DemoRoutingModule
 * @see DemoEntryRoutingId
 */
@Injectable({
    providedIn: "root"
})

export class DemoRouterService extends AbstractRouterService<DemoEntryRoutingId> {

    constructor(
        router: Router,
        @Inject(ErrorHandler) errorHandler: CommonErrorHandler

    ) {
        super(DemoEntryRoutingId.RouterService, router, errorHandler);
    }

    /**
     * Return the id from the route params
     *
     * It will return undefined if its not set
     */
    get id(): string {

        return this._route.snapshot.params["id"];
    }

    /**
     * Returns true if current route is a demo
     */
    get isDemo(): boolean {

        return this.isActivated && this._checkRouteId(DemoEntryRoutingId.Demo);
    }

    /**
     * Returns true if current route is the demo gallery
     */
    get isDemoGallery(): boolean {

        return this.isActivated && this._checkRouteId(DemoEntryRoutingId.DemoGallery);
    }


    /**
     * Navigate to the demo gallery
     */
    toDemoGallery(): void {

        this._navigate(["gallery"]);
    }

    /**
     * Navigate to the demo with the id
     */
    toDemo(demoId: string): void {

        if (StringUtil.emptyString(demoId)) {
            throw new InvalidParametersException("demoId is required");
        }
        this._navigate([demoId]);
    }

    /**
     * Exit to the Demo gallery in the module
     */
    exit(): void {

        this.toDemoGallery();
    }

    /**
     * Returns the navigation params for the parent routes for this Routing Module
     *
     * This will be prepended to all commands sent to the _navigate function and all commands returned by getChildModuleLoadCommands
     */
    protected _getParentCommands(): Array<string> {

        return ["demo"];
    }
}
