import { BrowserModule } from "@angular/platform-browser";
import {
    ErrorHandler, NgModule
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommonErrorHandler } from "@shopliftr/common-ng";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        MatSnackBarModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule
    ],
    providers: [
        {
            provide: "AppConfig",
            useValue: environment
        },
        {
            provide: ErrorHandler,
            useClass: CommonErrorHandler
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}
