/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
    Component,
    ErrorHandler,
    Inject
} from "@angular/core";
import { CommonErrorHandler } from "@shopliftr/common-ng";
import { DemoRouterService } from "./services/demo-router.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent {


    get showToDemoGalleryButton(): boolean {

        return !this._demoRouterService.isDemoGallery;
    }

    constructor(private readonly _demoRouterService: DemoRouterService,
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler) { }

    /**
     * Navigates do demo Gallery
     */
    onDemoGalleryClick(): void {
        this._demoRouterService.toDemoGallery();
    }

}
