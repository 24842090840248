import { NgModule } from "@angular/core";
import {
    Routes, RouterModule
} from "@angular/router";

const routes: Routes = [

    {
        path: "",
        pathMatch: "full",
        redirectTo: "demo/gallery"
    },
    {
        path: "demo",
        loadChildren: () => import("src/app/demo/demo.module").then((m) => m.DemoModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
